import React from "react"

const Footer = ()=>{
    return (
        <footer>
            <ul className="socials">
                <li><a href="https://linkedin.com/in/mihailo-evans/">
                    <svg viewBox="0 0 578.952 578.952"><use href="#linkedin"/></svg>
                </a></li>
                <li><a href="https://instagram.com/mihailo_evans/">
                    <svg viewBox="0 0 512 512"><use href="#instagram"/></svg>
                </a></li>
            </ul>
            <div className="copyright-container">Copyright &copy; {new Date().getFullYear()} Mihailo Evans</div>
        </footer>
    )
}

export default Footer