import * as React from "react"

const SvgDefinitions = ()=>(
    <svg className="svg-def" style={{display: 'none'}}>
        <symbol id="linkedin">
                <path className="social-svg" d="M484.093,342.107c0-19.176-2.449-35.902-7.344-50.184c-4.896-14.28-11.631-26.112-20.197-35.496
                    c-8.568-9.384-18.666-16.32-30.293-20.808c-11.629-4.488-24.379-6.732-38.25-6.732c-11.02,0-20.605,1.326-28.768,3.978
                    c-8.156,2.652-15.195,6.12-21.111,10.404s-10.914,8.874-14.994,13.77s-7.547,9.384-10.404,13.464v-35.496h-83.231
                    c0.408,6.936,0.612,20.4,0.612,40.392v75.891c0,0-0.204,45.084-0.612,135.252h83.231V345.779c0-3.672,0.205-7.238,0.613-10.709
                    c0.408-3.469,1.223-6.631,2.447-9.484c3.264-7.346,8.262-14.279,14.992-20.811c6.732-6.525,16.016-9.791,27.848-9.791
                    c15.096,0,25.908,5.201,32.436,15.605c6.529,10.402,9.793,24.174,9.793,41.311v134.641h83.232L484.093,342.107L484.093,342.107z
                    M141.372,200.736c14.688,0,26.316-4.284,34.884-12.852c8.568-8.568,12.648-18.768,12.24-30.6c0-12.24-4.182-22.542-12.546-30.906
                    c-8.364-8.364-19.686-12.546-33.966-12.546s-25.704,4.182-34.272,12.546S94.86,145.044,94.86,157.284
                    c0,11.832,4.08,22.032,12.24,30.6s19.38,12.852,33.66,12.852H141.372L141.372,200.736z M182.988,235.008H99.756v251.533h83.232
                    V235.008z M561.815,0c4.08,0,7.549,1.326,10.404,3.978c2.854,2.652,4.285,6.018,4.285,10.098v550.801
                    c0,4.08-1.432,7.443-4.285,10.098c-2.855,2.652-6.324,3.977-10.404,3.977H17.136c-4.08,0-7.548-1.322-10.404-3.977
                    s-4.284-6.018-4.284-10.098V14.076c0-4.08,1.428-7.446,4.284-10.098S13.056,0,17.136,0H561.815L561.815,0z"/>
        </symbol>
        <symbol id="instagram">
            <g className="social-svg">
                <path d="M505,257c0,34.8-0.7,69.7,0.2,104.5c1.5,61.6-37.2,109.2-86.5,130.4c-19.8,8.5-40.6,13-62.1,13c-67.3,0.1-134.7,1-202-0.3   c-50.7-1-92.4-22.2-122.3-64c-15.7-22-23.2-47-23.2-74.1c0-71.7,0-143.3,0-215c0-58.5,28.5-99.4,79.1-126C110.2,14,134.1,9.1,159,9   c65.3,0,130.7-0.4,196,0.2c50.7,0.4,93,19.8,124.2,60.6c17.4,22.8,25.8,49,25.8,77.8C505,184,505,220.5,505,257z M46,257   c0,36.7,0,73.3,0,110c0,16.4,3.8,31.8,12.3,45.7c22.3,36.5,56,54.3,97.8,55c67.1,1,134.3,0.4,201.5,0.2c16.5,0,32.5-3.4,47.4-10.5   c40.6-19.4,63.3-50.3,63.1-96.7c-0.4-71-0.1-142-0.1-213c0-20.1-5.7-38.5-17.6-54.7c-23-31.1-54.8-46.4-92.8-46.8   c-67-0.8-134-0.3-201-0.2c-14.3,0-28.1,2.9-41.5,7.9c-36.8,13.7-71,48.4-69.4,99.5C46.9,188,46,222.5,46,257z"/>
                <path d="M257.6,363c-64.5,0-116.5-51.4-116.6-115.4c-0.1-63,52.3-114.6,116.4-114.6c64.3-0.1,116.5,51.4,116.6,114.9   C374,311.3,321.9,362.9,257.6,363z M257.6,326c43.9,0,79.5-35.1,79.4-78.3c-0.1-42.8-35.7-77.8-79.4-77.8   c-43.9,0-79.7,34.9-79.7,78C178,291.1,213.7,326.1,257.6,326z"/>
                <path d="M387.5,98c13.5,0,24.5,11.5,24.5,25.6c-0.1,14.1-11.2,25.5-24.7,25.4c-13.3-0.1-24.2-11.5-24.2-25.3   C363,109.6,374,98,387.5,98z"/>
            </g>
        </symbol>
    </svg>
)

export default SvgDefinitions